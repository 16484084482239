import { matchPath } from 'react-router';
import get from 'lodash/get';
import last from 'lodash/last';
import {getCurrentLocale} from '../language';
import { hyphenateUrlComponents } from './boats';
import { getConfig } from '../../config/portal';
import { addResizeParams } from '../listingHelper';
import {getRouteConstantsFromI18n} from '../../tppServices/translations/constants';
import {dealWithLocalhost} from './location';

export const parseBoatDetailParams = (url, i18nService) => {
  const routeConstants = getRouteConstantsFromI18n(i18nService);
  let { params } = matchPath(url, routeConstants.BOAT_DETAILS_URL_PATTERN);
  return {
    id: last(params.id.split('-'))
  };
};

export const getLocaleUrl = (url, tpp) => {
  if (!url) {
    return '';
  }
  const config = tpp.configService().getConfig();
  const multiLanguage = config?.supports?.multiLanguage;
  if (multiLanguage) {
    const urlPathname = new URL(url).pathname;
    const locale = getCurrentLocale();
    // This will throw if language does not exist
    const translatedUrl = tpp.translateUrlSync(locale, urlPathname);
    return dealWithLocalhost(translatedUrl);
  }
  return url;
};

export const getBoatUrl = (listing, fullUrl, i18nService) => {
  const routeConstants = getRouteConstantsFromI18n(i18nService);
  let url = routeConstants.BOAT_DETAILS_ROOT;
  if (fullUrl) {
    const cfg = getConfig();
    const isMultipleDomainsHost = get(cfg, 'supports.multipleDomainsHost', false);
    if (isMultipleDomainsHost) {
      const currentLocale = getCurrentLocale();
      const { subdomain, domain, tld } = get(
        cfg,
        `languages.${currentLocale}`
      );
      url = `https://${subdomain}.${domain}${tld}${url}`;
    } else {
      url = get(getConfig(), 'host') + url;
    }
  }
  if (listing.year) {
    url += listing.year + '-';
  }
  if (listing.make) {
    url += hyphenateUrlComponents(listing.make) + '-';
  }
  if (listing.model) {
    url += hyphenateUrlComponents(listing.model) + '-';
  }
  url += listing.id + '/';

  return url;
};

export const imageUrlWithDimensions = (url, modified, type) => {
  const desktopDimensions = { width: 400, height: 267 };
  const sponsoredDimensions = { width: 300, height: 200 };
  let resizeDimensions = desktopDimensions;
  if (type && type.includes('sponsored carousel')) {
    resizeDimensions = sponsoredDimensions;
  }
  return addResizeParams(
    url,
    resizeDimensions,
    modified,
    true,
    'webp'
  );
};
